<template>
    <painel titulo="CRM - Checkout - Carrinhos" icone="pi pi-shopping-cart" :refreshFunction="atualizar" v-show="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Registro (DE)</label>
                    <Calendar v-model="dataRegistroDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Registro (ATÉ)</label>
                    <Calendar v-model="dataRegistroAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
            </div>
        </Panel>
        <msg-box :itens="mensagens"></msg-box>
        <tabela :data="carrinhos" dataKey="carrinhoId" :globalFilterFields="['nome', 'statusDescr', 'descricao']" stateKey="dt-crm-checkout-carrinhos">
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            <btn-detalhar :carrinho="slotProps.data"></btn-detalhar>
                        </div>
                    </template>
                </Column>
                <Column field="dataCadastro" header="Recebido em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                    </template>
                </Column>
                <Column field="descricao" header="Descrição" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.descricao }}
                    </template>
                </Column>
                <Column field="valorTotal" header="Valor" :sortable="true">
                    <template #body="slotProps">
                        {{ $numeral(slotProps.data.valorTotal).format('$0,0.00') }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true" filterField="status" :showFilterMatchModes="false">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
                <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import Services from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            carrinhos: null,
            dataRegistroAte: null,
            dataRegistroDe: null,
            carregado: false,
            filtros: null,
            carrinhosFiltrados: [],
            mensagens: [],
        };
    },

    methods: {
        obterCarrinhos() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataRegistroDe: this.$moment(this.dataRegistroDe).format('YYYY-MM-DD'),
                dataRegistroAte: this.$moment(this.dataRegistroAte).format('YYYY-MM-DD'),
            };
            Services.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.carrinhos = response.data;
                } else {
                    this.carrinhos = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterCarrinhos();
        },
    },

    created() {
        this.dataRegistroAte = new Date();
        this.dataRegistroDe = new Date(this.$moment(this.dataRegistroAte).subtract(1, 'months').format('YYYY-MM-DD'));
        this.obterCarrinhos();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'CRM_Checkout_Carrinhos_Listagem';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },
    },

    watch: {
        dataRegistroDe() {
            if (this.carregado) {
                this.obterCarrinhos();
            }
        },

        dataRegistroAte() {
            if (this.carregado) {
                this.obterCarrinhos();
            }
        },
    },
};
</script>

<style>
.filtro .p-panel-header {
    background-color: rgb(212, 246, 255) !important;
}
</style>
