<template>
    <font-awesome-icon
        icon="info-circle"
        @click="toDetalhar()"
        size="lg"
        class="icon-button p-text-primary"
        title="Detalhar"
        v-if="$temAcessoView('CRM-CHECKOUT-02')"
    />
</template>

<script>
export default {
    props: {
        carrinho: {
            type: Object,
        },
    },

    methods: {
        toDetalhar() {
            this.$router.push({
                name: 'CRM_Checkout_Carrinhos_Detalhar',
                params: {
                    id: this.carrinho?.carrinhoId,
                },
            });
        },
    },
};
</script>
